
















































































































































































































































































import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import DeliveryOrder from "@/store/entities/delivery-order";
import _ from "lodash";
import moment from "moment";

@Component({
  components: {},
})
export default class TaskCompleted extends Vue {
  modal = false;
  imageDialog = false;
  date = null;
  processing = false;
  currentImage = {
    url: null,
    title: null,
  };
  items: Array<DeliveryOrder> = [];
  async created() {
    this.processing = true;
    await this.$store.dispatch({
      type: "deliveryOrder/getAll",
    });
    this.processing = false;
    this.items = this.$store.state.deliveryOrder.list;
  }
  async popupImage(url, title) {
    this.imageDialog = true;
    this.currentImage = {
      url: url,
      title: title,
    };
  }
  get itemGroups() {
    let items = _(this.items)
      .groupBy((x) => moment(x.DeliveryDate).format("DD MMM YYYY"))
      .mapValues((value, key) => {
        let readableDate = moment(key).fromNow();
        let date = moment(key);
        if (date.isValid()) {
          if (date.isSame(moment(), "day")) readableDate = "Today";
          else if (date.isSame(moment().subtract(1, "day"), "day"))
            readableDate = "Yesterday";
          else readableDate = date.format("DD MMM YYYY");
        }

        return {
          date: key,
          items: value,
          readableDate: readableDate,
        };
      })
      .values()
      .orderBy(
        function(o) {
          return moment(o.date);
        },
        ["desc"]
      )
      .value();

    let date = moment(this.date);
    if (date.isValid()) {
      items = items.filter((x) => x.date == date.format("DD MMM YYYY"));
    }

    return items;
  }
}
